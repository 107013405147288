import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2';
import axios from '../../services/axios';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Tooltip } from '@mui/material';

export default function EmployeeList(props) {

    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSelectChange = (event, setFunction) => {
        setFunction(event.target.value);
    };

    useEffect(() => {
        const results = props.employeeList.filter((employee) =>
            (employee.name.toLowerCase().includes(searchTerm.toLowerCase())
                && (selectedDivision !== "" ?
                    selectedDivision === "0" ?
                        employee.division === null ? true : false : employee.division ?
                            employee.division.id === parseInt(selectedDivision) : false : true
                )
            ) || selectedEmployeeIds.includes(employee.id)
        );
        setSearchResults(results);
    }, [searchTerm, selectedDivision]);

    useEffect(() => {
        const results = props.employeeList.filter((employee) =>
            (employee.name.toLowerCase().includes(searchTerm.toLowerCase())
                && (selectedDivision !== "" ?
                    selectedDivision === "0" ?
                        employee.division === null ? true : false : employee.division ?
                            employee.division.id === parseInt(selectedDivision) : false : true
                )
            ) || selectedEmployeeIds.includes(employee.id)
        );
        setSearchResults(results);
        if (props.unselectOnEmployeeListChange) {
            setSelectedEmployeeIds([]);
        }
    }, [props.employeeList]);

    useEffect(() => {
        if (props.selectEffect) {
            props.selectEffect(selectedEmployeeIds);
        }
        const results = props.employeeList.filter((employee) =>
            employee.name.toLowerCase().includes(searchTerm.toLowerCase()) && (selectedDivision !== "" ?
                selectedDivision === "0" ?
                    employee.division === null ? true : false : employee.division ?
                        employee.division.id === parseInt(selectedDivision) : false : true
            ) || selectedEmployeeIds.includes(employee.id)
        );
        setSearchResults(results);
    }, [selectedEmployeeIds]);

    useEffect(() => {
        if (props.selectDivisionEffect) {
            props.selectDivisionEffect(selectedDivision);
        }
    }, [selectedDivision]);



    const handleEmployeeClick = (employee, e) => {
        e.preventDefault();
        if (props.allowMultiple) {
            if (selectedEmployeeIds.includes(employee.id)) {
                setSelectedEmployeeIds(selectedEmployeeIds.filter(id => id !== employee.id));
            } else {
                setSelectedEmployeeIds([...selectedEmployeeIds, employee.id]);
            }
        } else {
            if (!selectedEmployeeIds.includes(employee.id)) {
                setSelectedEmployeeIds([employee.id]);
            }
        }
    };

    const deleteAccount = async () => {
        Swal.fire({
            icon: "info",
            showConfirmButton: false,
            text: 'Deleting Accounts...'
        })
        await axios.post(`/api/account/delete`, { employees: selectedEmployeeIds }).then(({ data, status }) => {
            if (status === 200) {
                Swal.update({
                    icon: "info",
                    text: data.message
                })
            }
        })
    }

    const deleteAccountConfirm = () => {
        let emps = props.employeeList.filter(emp => selectedEmployeeIds.includes(emp.id)).map(emp => emp.name).join('</br> ');
        //console.log(emps);
        Swal.fire({
            title: `Delete Account`,
            html: `<div>
                        Delete accounts for the following people?
                        </br>
                        </br>
                        ${emps}
                    </div>`,
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-2',
                confirmButton: 'order-1 right-gap',
                //denyButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAccount();
            }
        })
    }

    return (
        <div className={`card p-0 ms-0 me-0 mt-0 mb-0${props.required ? " border-danger" : ""}`} style={props.minHeight || props.maxHeight ? { minHeight: `${props.minHeight || "300px"}`, maxHeight: `${props.maxHeight || '300px'}` } : props.subHeight ? { height: `calc(100% - ${props.subHeight})` } : { height: '100%' }}>
            <div className="card-header">
                <Row className="">
                    <Col className="text-center">
                        <Form.Label className={props.required ? "text-danger" : ""}>Employee List</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col className="m-0 p-0">
                        <Form.Control
                            type="text"
                            placeholder="Search Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Col>
                </Row>
                {props.show.division ? <Row>
                    <Col className="ms-0 me-0 mt-1 mb-0 p-0">
                        <Form.Select size="sm" onChange={event => handleSelectChange(event, setSelectedDivision)}>
                            <option value="">All Divisions</option>
                            <option value={0}>Unassigned</option>
                            {Object.entries(props.divisionList).map(([key, div], i) => {
                                return <option key={i} value={div.id}>{div.name}</option>
                            })}
                        </Form.Select>
                    </Col>
                </Row> : <></>}
            </div>
            <div style={{ overflow: 'auto' }}>
                <Card.Body className="pt-0 pb-1 ps-0 pe-0 m-0 emp-list-container">
                    <ListGroup className="" variant="flush">
                        <div className=''>
                            {searchResults.map((employee) => (
                                <ListGroup.Item
                                    className="pt-1 ps-3"
                                    key={employee.id}
                                    onClick={(e) => handleEmployeeClick(employee, e)}
                                    active={selectedEmployeeIds.includes(employee.id)}
                                    action
                                >
                                    <Row className="m-0">
                                        <Col className="fw-bold p-0" style={{ fontSize: '9pt' }}>
                                            <Tooltip title={`${employee.account ? "Registered" : "Not Registered"}`}>
                                                <AccountBoxIcon className={`${employee.account ? "text-success" : "text-danger"}`} />
                                            </Tooltip>
                                            {employee.name.toUpperCase()}
                                        </Col>
                                    </Row>
                                    {props.show.position ?
                                        <Row className="m-0">
                                            <Col className="font-italic p-0" style={{ fontSize: '9pt' }}>{employee.position}</Col>
                                        </Row> : null
                                    }
                                    {props.show.division ?
                                        <Row className="m-0">
                                            <Col className="font-italic p-0" style={{ fontSize: '9pt' }}>{employee.division ? employee.division.name : "Division Unassigned"}</Col>
                                        </Row> : null
                                    }
                                    {props.show.leavecredits || props.show.cto ?
                                        <Row className="m-0">
                                            <Col className="font-italic p-0" style={{ fontSize: '9pt' }}>
                                                {props.show.leavecredits ? `VL : ${employee.leavecredits.vl}; SL : ${employee.leavecredits.sl};` : ''}
                                                {props.show.cto ? ` CTO : ${employee.cto}` : ''}</Col>
                                        </Row> : null
                                    }
                                </ListGroup.Item>
                            ))}
                        </div>
                    </ListGroup>
                </Card.Body>
            </div>
            {props.show.actions ?
                <Card.Footer>
                    <Row className="mt-2" >
                        <Col className="p-0 m-0 text-center">
                            <Button className="m-0" size="sm" variant="danger" disabled={selectedEmployeeIds.length > 0 ? false : true} onClick={deleteAccountConfirm}>Delete Account</Button>
                        </Col>
                    </Row>
                </Card.Footer> : null
            }
        </div>
    );
}
