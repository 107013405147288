import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font} from '@react-pdf/renderer';
import numeral from 'numeral';
import HelveticaBold from './fonts/Helvetica-Bold.ttf'
import { DateObject } from "react-multi-date-picker";


// register font
Font.register({
  family: 'HelveticaBold',
  src: HelveticaBold
});

const logo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Official_Seal_of_Pangasinan.svg/478px-Official_Seal_of_Pangasinan.svg.png';
const formatDateIfDate = (value) => {
  if (!value) {
    return value;
  }
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  return value;
};




// Define margins (in points, 1 inch = 72 points)
const margins = {
  top: 36,       // 0.5 inch
  right: 18,     // 0.25 inch
  bottom: 46,    // 0.25 inch
  left: 18,      // 0.25 inch
};

// Styles
const styles = StyleSheet.create({
  divider: {
    borderBottom: '1pt solid black', // Bottom border style and color
    borderBottomWidth: 2,            // Bottom border thickness in points
    marginBottom: 10,
    marginTop: 10,
    width: 576,
  },

  horizontalLine: {
    borderBottom: '1pt solid black', // Bottom border style and color
    width: 576,  
  },

  page: {
    flexDirection: 'column',
    marginLeft: margins.left,
    marginRight: margins.right,
    paddingTop: margins.top,
    paddingBottom: margins.bottom,
    fontFamily: 'Helvetica',
    fontSize: 10
  },

  logo: {
    width: 72,
    height: 72,
    marginBottom: 3,
  },

  reportheader: {
    width: 576,
    height: 'auto',
    alignItems: 'center',
  },

  content: {
    fontSize: 12,
    marginBottom: 10,
  },
  heading : {
    fontSize: 10,
    fontFamily: 'HelveticaBold',
  },
  pageNumber: {
    position: 'absolute',
    bottom: margins.bottom,
    left: null,
    right: margins.right,
    fontSize: 10,
    textAlign: 'right',
  },
  rowdirection: {
    fontSize: 10,
    flexDirection: 'row', // Arrange contents in a row
    alignItems: 'center', // Align contents vertically in the center
    justifyContent: 'space-between', // Distribute contents with space between them
    width: 576
  },

  spacer: {
    marginBottom: 5,
  },

  cell: {
    width: 192,
    fontFamily: 'Helvetica',
    textAlign: 'center',
    margin: 0,
    fontSize: 10,
  },

  detailrow: {
    flexDirection: 'row', // Arrange contents in a row
    width: 576,
    borderLeft: 1, 
    borderRight: 1, 
    borderBottom: 1, 
  },
  detailcell1: {
    fontFamily: 'Helvetica',
    width: 64,
    fontSize: 7,
  },

 detailcell2: {
  fontFamily: 'Helvetica',
    width: 128,
    fontSize: 7,
  },

  headingcontainter: {
    flexDirection: 'row', // Arrange contents in a row
    width: 192,
  },
  detailgroup: {
    flexDirection: 'row', // Arrange contents in a row
    width: 192,
  },

  headingheight: {
    height: 20,
  },

  bold: {
    fontFamily: 'HelveticaBold',
  },

  rightborder: {borderRight: 1,},

  uppertableheader: {
    flexDirection: 'row', // Arrange contents in a row
    width: 576,
    border: '1pt solid black', // Border style and color
    backgroundColor: 'gray'
  },
  lowertableheader: {
    flexDirection: 'row', // Arrange contents in a row
    width: 576,
    borderLeft: 1, // Border style and color
    borderRight: 1, // Border style and color
    borderBottom: 1, // Border style and color
    backgroundColor: 'lightgray'
  },

  uppertableheadertexts: {
    flexDirection: 'row',
    width: 192,
    textAlign: 'right',
    fontSize: 10,
  },

  footer: {
    position: 'absolute', // Position the footer absolutely
    bottom: 18, // Adjust this value as needed to control the distance from the bottom
    left: 0,
    width: 576,
    flexDirection: 'row', // Arrange contents in a row
    alignItems: 'center', // Align contents vertically in the center
    justifyContent: 'space-between', // Distribute contents with space between them
    fontSize: 10,
    fontFamily: 'Helvetica',
  },

  footerseal: {
    position: 'absolute', // Position the footer absolutely
    bottom: 40, // Adjust this value as needed to control the distance from the bottom
    left: 0,
    width: 576,
    flexDirection: 'row', // Arrange contents in a row
    alignItems: 'center', // Align contents vertically in the center
    justifyContent: 'space-between', // Distribute contents with space between them
    fontSize: 10,
    fontFamily: 'Helvetica',

  },

  footerdetails: {
    fontSize: 8,
    width: 288,
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  },

  footerdetailseal: {
    fontSize: 8,
    width: 576,
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  },

  wrapperheight: {
    // height: 500
  }

});

let vlbalance=0
let slbalance=0

// Create PDF component
const LeaveLedger = (props) => {
  const isValidDate = (value) => {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  };
  const summarizeDates = (dates) => {
    let dStr = "";
    let curM = null;
    let curD = null;
    let curY = null;
    let fD = null;
    let m, d, y = null;
    dates.map(date => {
        let dtC = new DateObject(date);
        m = dtC.month.number;
        d = dtC.day;
        y = dtC.year;
        if (curM !== m) {
            if (curM !== null) {
                if (curD === null) {
                    dStr += d;
                    fD = d;
                }
                if ((curD !== (d - 1)) && (curD !== null)) {
                    if (curD !== fD && curD !== (d - 1)) {
                        dStr += `-${curD}`;
                    }
                    fD = d;
                }
            }

            if (curM !== null) {
                dStr += `/${curY} | `;
            }

            dStr += `${m}/`;
            dStr += `${d}`;

            curD = d;
            curM = m;
            curY = y;
            if (fD === null) {
                fD = d;
            }
        } else {
            if (curD === null) {
                dStr += d;
                fD = d;
            }

            if ((curD !== (d - 1)) && curD !== null) {
                if (curD !== fD) {
                    dStr += `-${curD}`;
                }
                dStr += `,${d}`;
                fD = d;
            }
            curD = d;
        }
    });

    if (curD !== fD && fD !== null) {
        dStr += `-${d}`;
    }
    dStr += `/${y}`;

    return dStr;
  }
  return (
    <div>
    <PDFViewer style={{ width: '100%', height: '75vh' }}>
      <Document>

        <Page size="letter" style={styles.page}>
          <View fixed>
          <View style={styles.reportheader}>
            <Image src={logo} style={styles.logo} />
            <Text>Republic of the Philippines</Text>
            <Text style={{ fontFamily: 'HelveticaBold' }}>PROVINCE OF PANGASINAN</Text>
            <Text style={{ fontFamily: 'HelveticaBold' }}>HUMAN RESOURCE MANAGEMENT AND DEVELOPMENT OFFICE</Text>
            <Text>1st Floor, Palaris Building, Capitol Compound, Lingayen, Pangasinan</Text>
            <Text>Contact Nos.:      HR Main Office - 0928-440-2568 / 0965-036-7848</Text>
            <Text>Center for Excellence - 0928-362-7146 / 0965-036-7854</Text>
            <Text>Email Address: humanresource@hrmdolgupangasinan.com; centerforexcellence@hrmdolgupangasinan.com</Text>
          </View>
          <View style={styles.divider}>
            
          </View>
          <View style={styles.rowdirection}>
            <Text style={{ textAlign: 'center' }}>Name: {props.leaveData?.employee[0].employee}</Text>
            <Text style={{ textAlign: 'right' }}>Salary: {props.leaveData?.employee[0].salary ? numeral(props.leaveData?.employee[0].salary).format('0,0.00') : '0.00'}</Text>
          </View>
          <Text>Official Designation: {props.leaveData?.employee[0].Position}</Text>
          <Text>Department: {props.leaveData?.employee[0].department}</Text>
          <View style={styles.spacer}></View>

          <View style={styles.uppertableheader}>
              <Text style={[styles.cell,styles.rightborder,styles.bold,{ fontFamily: 'HelveticaBold' }]}>PERIOD</Text>
              <Text style={[styles.cell,styles.rightborder,styles.bold, { fontFamily: 'HelveticaBold' }]}>VACATION LEAVE</Text>
              <Text style={[styles.cell,styles.bold, { fontFamily: 'HelveticaBold' }]}>SICKLEAVE</Text>
          </View>
          <View style={styles.lowertableheader}>
              <View style={[styles.headingcontainter,styles.rightborder]}>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>From</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>To</Text>
                <Text style={[styles.detailcell2,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Remarks</Text>
              </View>
              <View style={[styles.headingcontainter,styles.rightborder]}>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Taken</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Earned</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Undertime W/P</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Undertime W/O P</Text>
                <Text style={[styles.detailcell1,{ textAlign: 'center' }]}>Balance</Text>
              </View>
              <View style={styles.headingcontainter}>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Taken</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Earned</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Undertime W/P</Text>
                <Text style={[styles.detailcell1,styles.rightborder,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Undertime W/O P</Text>
                <Text style={[styles.detailcell1,{ textAlign: 'center', fontFamily: 'HelveticaBold' }]}>Balance</Text>
              </View>
          </View>
          </View>
          {props.leaveData?.ledger.map((leaveinfo, index) => {
            
            
            const taken1 = parseFloat(leaveinfo[3])
            const earned1 = parseFloat(leaveinfo[4])
            const wopay1 = parseFloat(leaveinfo[5])

            const taken2 = parseFloat(leaveinfo[7]);
            const earned2 = parseFloat(leaveinfo[8]);
            const wopay2 = parseFloat(leaveinfo[9]);
            const inclusive = isValidDate(leaveinfo[11].split(', ')[0]) ? summarizeDates(leaveinfo[11].split(', ')) : ''

            vlbalance=parseFloat((vlbalance-taken1+earned1-wopay1).toFixed(4))
            slbalance=parseFloat((slbalance-taken2+earned2-wopay2).toFixed(4))
            
          
            return (
            <View style={styles.detailrow} wrap={false}>
            <View style={[styles.detailgroup,styles.rightborder]}>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{formatDateIfDate(leaveinfo[0])}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{formatDateIfDate(leaveinfo[1])}</Text>
              <Text style={[styles.detailcell2]}>{(leaveinfo[2] !==null && leaveinfo[2] !=='SPL' ? leaveinfo[2] + ' ' : '') + inclusive + (leaveinfo[2] ==='SPL' ? ' (SPL - ' + parseFloat(leaveinfo[12]).toString() + ' day' + (leaveinfo[12]>1 ? 's' : '') + ')':'')}</Text>
            </View>
            <View style={[styles.detailgroup,styles.rightborder]}>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[3]}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[4]}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{numeral(leaveinfo[5]).format('0,0.0000')}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{numeral(leaveinfo[6]).format('0,0.0000')}</Text>
              <Text style={[styles.detailcell1,{textAlign: 'center', fontSize: 8}]}>{numeral(vlbalance).format('0,0.0000')}</Text>
            </View>
            <View style={styles.detailgroup}>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[7]}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[8]}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[9]}</Text>
              <Text style={[styles.detailcell1,styles.rightborder,{textAlign: 'center', fontSize: 8}]}>{leaveinfo[10]}</Text>
              <Text style={[styles.detailcell1,{textAlign: 'center', fontSize: 8}]}>{numeral(slbalance).format('0,0.0000')}</Text>
            </View>      
          </View>
          )})}
          

          <View style={[styles.detailrow,{height: 20, fontFamily: 'HelveticaBold'}]}>
            <View style={[styles.detailgroup,{borderRight: 1, justifyContent: 'flex-end', alignItems: 'center'}]}>
              <Text>Total Vacation Leave</Text>
            </View>
            <View style={[styles.detailgroup,{ borderRight: 1,width: 76.8,justifyContent: 'center', alignItems: 'center', backgroundColor: 'gray', fontFamily: 'HelveticaBold'}]}>
              <Text>{numeral(vlbalance).format('0,0.0000')}</Text>
            </View>
            <View style={[styles.detailgroup,{ borderRight: 1,width: 76.8,justifyContent: 'center', alignItems: 'center'}]}>
              <Text>Total Sick Leave</Text>
            </View>
            <View style={[styles.detailgroup,{ borderRight: 1,width: 76.8,justifyContent: 'center', alignItems: 'center', backgroundColor: 'gray', fontFamily: 'HelveticaBold'}]}>
              <Text>{numeral(slbalance).format('0,0.0000')}</Text>
            </View>
            <View style={[styles.detailgroup,{ borderRight: 1,width: 76.8,justifyContent: 'center', alignItems: 'center'}]}>
              <Text>Total Balance</Text>
            </View>
            <View style={[styles.detailgroup,{width: 76.8,justifyContent: 'center', alignItems: 'center', backgroundColor: 'gray', fontFamily: 'HelveticaBold'}]}>
              <Text>{numeral(vlbalance+slbalance).format('0,0.0000')}</Text>
            </View>
          </View>
          {vlbalance=0}{slbalance=0}
          <View style={{ alignItems: 'center', paddingTop: 10 }} wrap={false}>
            <View style={styles.rowdirection}>
              <View style={{width: 192}}>
                <Text style={{paddingBottom: 20}}>{props.sig1?.name ? 'Prepared by:': ''}</Text>
                <Text style={{textAlign: 'center', fontFamily: 'HelveticaBold'}}>{props.sig1?.name}</Text>
                <Text style={{textAlign: 'center'}}>{props.sig1?.position}</Text>
              </View>
              <View style={{width: 192}}>
                <Text style={{paddingBottom: 20}}>{props.sig2?.name ? 'Reviewed by:': ''}</Text>
                <Text style={{textAlign: 'center', fontFamily: 'HelveticaBold'}}>{props.sig2?.name}</Text>
                <Text style={{textAlign: 'center'}}>{props.sig2?.position}</Text>
              </View>
            </View>
            <View style={{width: 192}}>
                <Text style={{paddingBottom: 20}}>{props.sig3?.name ? 'Certified by:': ''}</Text>
                <Text style={{textAlign: 'center', fontFamily: 'HelveticaBold'}}>{props.sig3?.name}</Text>
                <Text style={{textAlign: 'center'}}>{props.sig3?.position}</Text>
            </View >
            
          </View>

          
          <View style={styles.footerseal}>
              <View style={styles.footerdetailseal}> 
                <Text style={[styles.footerdetailseal,{textAlign: 'center', fontSize: 8}]}>Official Seal</Text>
              </View>
          </View>
          <View style={styles.footer} fixed>
            <View style={styles.footerdetails}>
              <Text style={styles.footerdetails}>{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Text>
              <Text style={styles.footerdetails}>Source: Human Resource Information System Generated Report</Text>
            </View>
          
            <View style={styles.footerdetails}>
              <Text style={[styles.footerdetails,{textAlign: 'right', fontSize: 8}]}>Employee Leave Ledger</Text>
              <Text style={[styles.footerdetails,{textAlign: 'right', fontSize: 8}]} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}/>
            </View>
          </View>

        </Page>
      </Document>
    </PDFViewer>
    </div>
    
  );
};

export default LeaveLedger;