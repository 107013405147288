import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';

import EmployeeList from '../../Global/EmployeeList.component';

export default function CreateModal(props) {
    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [employeeCredit, setEmployeeCredit] = useState(null);
    const [employeeList, setEmployeeList] = useState([]);

    const fetchEmployeeList = async () => {
        await axios.post(`/api/office/query/${props.user.office.offcode}`, { employee: true, leavecredits: false, cto: false }).then(({ data }) => {
            setDivisionList(data.office.divisions);
            setEmployeeList(data.office.employees);
        })
    }

    const fetchEmpCredit = async () => {
        setEmployeeCredit(null);
        if (selectedEmployee !== null && selectedEmployee.length !== 0) {
            await axios.get(`/api/employee/leavebalance`, { params: { user_id: selectedEmployee[0] } }).then(({ data }) => {
                setEmployeeCredit(data.credit);
            });
        }
    }

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    useEffect(() => {
        fetchEmpCredit();
    }, [selectedEmployee]);

    const [leaveReq, setLeaveReq] = useState({
        1: [
            "It shall be filed five (5) days in advance, whenever possible, of the effective date of such leave. Vacation leave within in the Philippines or abroad shall be indicated in the form for purposes of securing travel authority and completing clearance from money and work accountabilities.",
            [
                "If Travelling abroad:",
                "- Request Letter",
                "- Endorsement Letter",
                "- PTO Clearance",
                "- GSO Clearance",
                "- Accounting Clearance",
                "- PGO Clearance"
            ]
        ],
        2: [
            "Annual five-day vacation leave shall be forfeited if not taken during the year. In case the scheduled leave has been cancelled in the exigency of the service by the head of agency, it shall no longer be deducted from the accumulated vacation leave. Availment of one (1) day or more Vacation Leave (VL) shall be considered for complying the mandatory/forced leave subject to the conditions under Section 25, Rule XVI of the Omnibus Rules Implementing E.O. No. 292."
        ],
        3: [
            "It shall be filed immediately upon employee's return from such leave.",
            "If filed in advance or exceeding five (5) days, application shall be accompanied by a medical certificate. In case medical consultation was not availed of, an affidavit should be executed by an applicant."
        ],
        4: [
            "Proof of pregnancy e.g. ultrasound, doctor’s certificate on the expected date of delivery",
            "Accomplished Notice of Allocation of Maternity Leave Credits (CS Form No. 6a), if needed",
            "Seconded female employees shall enjoy maternity leave with full pay in the recipient agency."
        ],
        5: [
            "Proof of child’s delivery e.g. birth certificate, medical certificate and marriage contract"
        ],
        6: [
            "It shall be filed/approved for at least one (1) week prior to availment, except on emergency cases. Special privilege leave within the Philippines or abroad shall be indicated in the form for purposes of securing travel authority and completing clearance from money and work accountabilities."
        ],
        7: [
            "It shall be filed in advance or whenever possible five (5) days before going on such leave with updated Solo Parent Identification Card."
        ],
        8: [
            "Shall meet the agency’s internal requirements, if any;",
            "Contract between the agency head or authorized representative and the employee concerned."
        ],
        9: [
            "It shall be filed in advance or immediately upon the woman employee’s return from such leave.",
            "It shall be accompanied by any of the following supporting documents:",
            [
                "a. Barangay Protection Order (BPO) obtained from the barangay;",
                "b. Temporary/Permanent Protection Order (TPO/PPO) obtained from the court;",
                "c. If the protection order is not yet issued by the barangay or the court, a certification issued by the Punong Barangay/Kagawad or Prosecutor or the Clerk of Court that the application for the BPO, TPO or PPO has been filed with the said office shall be sufficient to support the application for the ten-day leave; or",
                "In the absence of the BPO/TPO/PPO or the certification, a police report specifying the details of the occurrence of violence on the victim and a medical certificate may be considered, at the discretion of the immediate supervisor of the woman employee concerned."
            ]
        ],
        10: [
            "The application may be filed in advance, that is, at least five (5) days prior to the scheduled date of the gynecological surgery that will be undergone by the employee. In case of emergency, the application for special leave shall be filed immediately upon employee’s return but during confinement the agency shall be notified of said surgery.",
            "The application shall be accompanied by a medical certificate filled out by the proper medical authorities, e.g. the attending surgeon accompanied by a clinical summary reflecting the gynecological disorder which shall be addressed or was addressed by the said surgery; the histopathological report; the operative technique used for the surgery; the duration of the surgery including the peri-operative period (period of confinement around surgery); as well as the employees estimated period of recuperation for the same."
        ],
        11: [
            "Application shall be made within one (1) week from the time of the accident except when a longer period is warranted.",
            "Letter request supported by relevant reports such as the policereport, if any.",
            "Medical certificate on the nature of the injuries, the course of treatment involved, and the need to undergo rest, recuperation, and rehabilitation, as the case may be.",
            "Written concurrence of a government physician should be obtained relative to the recommendation for rehabilitation if the attending physician is a private practitioner, particularly on the duration of the period of rehabilitation."
        ],
        12: [
            "The special emergency leave can be applied for a maximum of five (5) straight working days or staggered basis within thirty (30) days from the actual occurrence of the natural calamity/disaster. Said privilege shall be enjoyed once a year, not in every instance of calamity or disaster.",
            "The head of office shall take full responsibility for the grant of special emergency leave and verification of the employee’s eligibility to be granted thereof. Said verification shall include: validation of place of residence based on latest available records of the affected employee; verification that the place of residence is covered in the declaration of calamity area by the proper government agency; and such other proofs as may be necessary."
        ],
        13: [
            "Application for adoption leave shall be filed with an authenticated copy of the Pre-Adoptive Placement Authority issued by the Department of Social Welfare and Development (DSWD)."
        ],
        14: [],
        15: [],
        16: [],
        17: [
            "Application for monetization of fifty percent (50%) or more of the accumulated leave credits shall be accompanied by letter request to the head of the agency stating the valid and justifiable reasons."
        ],
        18: [
            "Filled up application for Terminal Leave signed by the employees and head of office.",
            [
                "HRMDO:",
                "- Certified Service Record",
                "- Certification of No Pending Administrative Case",
                "- Clearance Form signed by concerned head (clearance from work, money and property accountabilities)",
                "- Copy of latest Appointment / NOSA / NOSI",
                "- Copy of latest Assets & Liabilities",
                "- Copy of updated Leave Card",
                "- Exit Interview Report"
            ],
            [
                "RESIGNATION:",
                "- Acceptance letter signed by the Governor/ Vice Governor",
                "- Employee's Resignation Letter (Casual Employee)"
            ],
            [
                "TRANSFER:",
                "- Approval letter signed by the Governor/Vice Governor",
                "- Employee's Request for Transfer",
                "- Certification of Last Salary Received",
                "- Certification of Leave Credits"
            ],
            [
                "GSIS:",
                "- Application Form for Retirement",
                "- Certified Service Record"
            ],
            [
                "PAcO:",
                "Certification of no accountabilities for suspension/s and/or disallowances from the Accounting Office based on the certification from the following financial institutions:",
                "- Veterans - Lingayen Branch (Regular Employee)",
                "- Landbank - Lingayen Branch (Regular Employee)",
                "- PGEMPCOP",
                "- PPEMCO"
            ],
            [
                "PTO:",
                "- Certification of no money accountability"
            ],
            [
                "GSO:",
                "- Certification of no property responsibility"
            ],
            [
                "Library:",
                "- Certification of no property accountability on any other book/library material"
            ],
            [
                "Housing:",
                "- Certification of no financial obligation on housing loans"
            ],
            [
                "For Hospital Employees:",
                "- Clearance from money and property responsibilities signed by concerned Chiefs of Service and Chief of Hospital"
            ],
            [
                "Office of the Provincial Prosecutor:",
                "- Certification of no criminal records, no pending complaint/case filed against the employee"
            ]
        ]
    });

    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const [leaveDetailOptions, setLeaveDetailOptions] = useState([]);
    const [meridiem, setMeridiem] = useState("");

    const [selectedType, setSelectedType] = useState(0);
    const [selectedDetail, setSelectedDetail] = useState(0);
    const [detailDescription, setDetailDescription] = useState("");
    const [noDays, setNoDays] = useState(0);
    const [noHours, setNoHours] = useState(0);
    const setConNoDays = function (num, days = true) {
        if (days) {
            setNoDays(num);
            setNoHours(num * 8);
        } else {
            setNoHours(num);
            setNoDays(num / 8);
        }
    };
    const [inclusiveDates, setInclusiveDates] = useState([]);
    const [commutation, setCommutation] = useState(0);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileChkFail, setFileChkFail] = useState([]);
    const fileInputRef = useRef();
    const handleFileChange = (event) => {
        let files = Array.from(event.target.files);
        const label = document.getElementById('fileLabel');

        const allowedFileType = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
        const maxSize = 2 * 1024 * 1024;

        if (files.length > 0) {
            let newSelectedFiles = [...selectedFiles];
            let newFileChkFail = [];
            files.map((file, key) => {
                let ext = file.name.split('.').pop();
                let size = file.size;
                let dupe = newSelectedFiles.filter(ff => { return ff.name === file.name }).length ? true : false;
                if (allowedFileType.includes(ext) && size <= maxSize && !dupe) {
                    newSelectedFiles.push(file);
                } else {
                    newFileChkFail.push(`${file.name} : ${allowedFileType.includes(ext) ? "" : `${ext} file type is now allowed${(size <= maxSize || dupe) ? "" : ", "}`}${size <= maxSize ? "" : `file size is larger than 2mb${dupe ? ", " : ""}`}${dupe ? "duplicate file name was chosen, remove existing file or rename the current file" : ""}`);
                }
            })
            setSelectedFiles(newSelectedFiles);
            setFileChkFail(newFileChkFail);
        } else {

        }
    }

    useEffect(() => {
        fetchLeaveApplicationOptions();
    }, [])

    const fetchLeaveApplicationOptions = async () => {
        await axios.get(`/api/leaveapplication/options`).then(({ data }) => {
            setLeaveTypeOptions(data.types);
            setLeaveDetailOptions(data.details);
        })
    }

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementById("leaveApplicationForm").querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            //console.log(el.value);
            if (el.value == 0) {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })

        let dates = document.getElementById("dates-list");
        if (inclusiveDates.length === 0 && selectedType != 18) {
            invalid = true;
            dates.classList.add("text-danger");
        } else {
            dates.classList.remove("text-danger");
        }

        return invalid;
    }

    const submitLeaveApplication = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            const formData = new FormData()

            formData.append('unique', selectedEmployee)
            formData.append('type', selectedType)
            formData.append('detail', selectedDetail)
            formData.append('detail_description', detailDescription)
            formData.append('meridiem', meridiem)
            // formData.append('no_days', parseInt(noDays))
            formData.append('no_days', parseFloat(noDays))
            formData.append('commutation', parseInt(commutation))
            inclusiveDates.forEach((date) => {
                formData.append('leave_date[]', date.format("YYYY-MM-DD"));
            });
            // inclusiveDates.forEach((dateRange) => {
            //     if(dateRange.length === 2){
            //         let sDate = new DateObject(dateRange[0].toDate());
            //         let eDate = dateRange[1];
            //         while (sDate.toDate() <= eDate.toDate()) {
            //             formData.append('leave_date[]', sDate.format("YYYY-MM-DD"));
            //             sDate.add(1, "day");
            //         }
            //     }else{
            //         formData.append('leave_date[]', dateRange[0].format("YYYY-MM-DD"));
            //     }
            // });
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('document[]', selectedFiles[i]);
            }

            Swal.fire({
                showConfirmButton: false,
                text: selectedFiles.length > 0 ? `File Uploading: 0%` : 'Saving...'
            });
            await axios.post(`/api/leaveapplication/create`, formData, {
                onUploadProgress: (data) => {
                    if (selectedFiles.length > 0) {
                        Swal.update({
                            text: `File Uploading: ${Math.round((data.loaded / data.total) * 100)}%`
                        });
                    }
                },
            }).then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                }).then(() => {
                    props.closeFetchCreate();
                });
            }).catch((response) => {
                Swal.update({
                    icon: "error",
                    text: response.message,
                    showConfirmButton: true
                })
            })
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Leave Application</Modal.Title>
            </Modal.Header>
            <Form id="leaveApplicationForm" onSubmit={submitLeaveApplication}>
                <Modal.Body className="leave-application-view-modal-body">
                    <Row className="leave-application-view-modal-body-leave overflow-auto">
                        <Col xs={12} sm={7}>
                            <Row>
                                <Col className="mb-3" xs={12}>
                                    <EmployeeList
                                        divisionList={divisionList}
                                        employeeList={employeeList}
                                        selectEffect={setSelectedEmployee}
                                        allowMultiple={false}
                                        minHeight={'300px'}
                                        maxHeight={'300px'}
                                        show={
                                            {
                                                leavecredits: false,
                                                cto: false
                                            }
                                        }
                                    />
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="fw-bold">Employee Credits</Form.Label><br />
                                    VL : {employeeCredit ? parseFloat(employeeCredit.vl_balance).toFixed(3) : ""}<br />
                                    SL : {employeeCredit ? parseFloat(employeeCredit.sl_balance).toFixed(3) : ""}<br />
                                    CTO : {employeeCredit ? parseFloat(employeeCredit.cto_balance).toFixed(3) : ""}
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="fw-bold">TYPE OF LEAVE TO BE AVAILED</Form.Label>
                                    <Form.Select name="selectedType" required onChange={(event) => {
                                        event.target.classList.remove("is-invalid");
                                        setSelectedType(event.target.value);
                                    }}>
                                        <option value={0}>Select</option>
                                        {leaveTypeOptions.map((type) => (
                                            <option key={type.id} value={type.id}>{type.type}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="fw-bold">DETAILS OF LEAVE</Form.Label>
                                    <Form.Select name="selectedDetail" required onChange={(event) => {
                                        event.target.classList.remove("is-invalid");
                                        setSelectedDetail(event.target.value);
                                    }}>
                                        <option value={0}>Select</option>
                                        {leaveDetailOptions.map((detail) => (
                                            <option key={detail.id} value={detail.id}>{`${detail.detail}${detail.otherDetail}`}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control className="mt-2" name="detailDescription" as="input" placeholder="Specify when needed" value={detailDescription} onChange={(event) => {
                                        setDetailDescription(event.target.value)
                                    }} />
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="m-0">No. of Working Days</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Label className="m-0 fw-normal">(In Days)</Form.Label>
                                            <Form.Control size="sm" name="noDays" as="input" type="number" min="0" step="0.001" pattern="\d+(\.\d{1,3})?" value={noDays} required onChange={(event) => {
                                                event.target.classList.remove("is-invalid");
                                                setConNoDays(event.target.value);
                                            }} />
                                        </Col>
                                        <Col>
                                            <Form.Label className="m-0 fw-normal">(In Hours)</Form.Label>
                                            <Form.Control size="sm" name="noHours" as="input" type="number" min="0" step="0.001" pattern="\d+(\.\d{1})?" value={noHours} required onChange={(event) => {
                                                event.target.classList.remove("is-invalid");
                                                setConNoDays(event.target.value, false);
                                            }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-2" sm={12}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="m-0" >Time of Day</Form.Label>
                                            <Form.Select style={{ paddingLeft: 10, paddingTop: 0, paddingBottom: 0 }} name="meridiem" value={meridiem} onChange={(event) => {
                                                setMeridiem(event.target.value);
                                            }}>
                                                {[{ text: "Whole", value: "" }, { text: "AM", value: "am" }, { text: "PM", value: "pm" }].map((opt) => (
                                                    <option key={opt.value}
                                                        value={opt.value}
                                                    //selected={opt.value === commutation}
                                                    >{opt.text}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="fw-bold" id="dates-list">INCLUSIVE DATES</Form.Label>
                                    <Calendar className="text-center"
                                        multiple
                                        // range
                                        value={inclusiveDates}
                                        onChange={setInclusiveDates}
                                        plugins={[
                                            <DatePanel sort="date" />
                                        ]}
                                    />
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="fw-bold" >Commutation</Form.Label>
                                            <Form.Select name="commutation" value={commutation} onChange={(event) => {
                                                setCommutation(event.target.value);
                                            }}>
                                                {[{ text: "Not Requested", value: 0 }, { text: "Requested", value: 1 }].map((opt) => (
                                                    <option key={opt.value}
                                                        value={opt.value}
                                                    //selected={opt.value === commutation}
                                                    >{opt.text}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Row>
                                <Col className="mb-2" xs={12}>
                                    <Form.Label className="fw-bold mb-0 me-1">Attachments (If needed)</Form.Label>
                                    <Form.Control name="attachments" as="input" type="file" ref={fileInputRef} hidden onChange={handleFileChange} multiple={true} />
                                    <Button className="" variant="primary" size="sm" onClick={() => fileInputRef.current.click()}>
                                        Upload File
                                    </Button>
                                </Col>
                                <Col className="" xs={12}>
                                    {selectedFiles.map((file, key) => {
                                        return (
                                            <div className="card ms-3 me-3 mb-2" key={key}>
                                                <div className="card-body pt-1 pb-1">
                                                    <Form.Label className="fw-normal p-0 m-0">{file.name}</Form.Label>
                                                    <Button className="float-right" variant="danger" size="sm" title={`Remove "${file.name}"`} onClick={(e) => {
                                                        let newSelectedFiles = [...selectedFiles];
                                                        newSelectedFiles.splice(key, 1);
                                                        setSelectedFiles(newSelectedFiles);
                                                    }}>X</Button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Col>
                                <Col className="mb-2" xs={12}>
                                    <ListGroup className="ps-3 pe-3" variant="flush">
                                        {fileChkFail.map((file, key) => {
                                            return (
                                                <ListGroup.Item className="p-1" key={key}>
                                                    <Form.Label className="fw-normal p-0 m-0 me-2 text-danger">{file}</Form.Label>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </Col>
                                {leaveReq[selectedType] ?
                                    <Col className="mb-2" xs={12}>
                                        <Form.Label className="fw-normal fst-italic">Instructions and Requirements</Form.Label>
                                        <ul className="mb-0">
                                            {leaveReq[selectedType].map((requ, key) => {
                                                return <li key={key}>
                                                    {typeof requ === 'string' ? requ :
                                                        <ul className="m-0 p-0" style={{ listStyleType: "none" }}>
                                                            {requ.map(((req, key2) => {
                                                                return <li key={key2}>
                                                                    {req}
                                                                </li>
                                                            }))}
                                                        </ul>
                                                    }
                                                </li>
                                            })}
                                        </ul>

                                    </Col> : null}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="m-auto">
                        <Col className="pt-2 text-center" xs={12}>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}