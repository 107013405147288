import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Swal from 'sweetalert2';
import axios from '../services/axios';

const username_regex = /^[a-zA-Z0-9]{8,50}$/
const unique_regex = /^\d{4,}$/
const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

function Registration() {
  const navigate = useNavigate()
  const focusRef = useRef()
  const errRef = useRef()
  //const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

  const [username, setUsername] = useState('')
  const [validUsername, setValidUsername] = useState(false)
  const [usernameFocus, setUsernameFocus] = useState(false)

  const [unique, setUnique] = useState('')
  const [validUnique, setValidUnique] = useState(false)
  const [uniqueFocus, setUniqueFocus] = useState(false)

  //const [email, setEmail] = useState('')
  //const [validEmail, setValidEmail] = useState(false)
  //const [emailFocus, setEmailFocus] = useState(false)

  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [matchPassword, setMatchPassword] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  //email regex
//   useEffect(() => {
//     setValidEmail(email_regex.test(email))
//   }, [email])

    useEffect(() => {
        setValidUsername(username_regex.test(username));
    }, [username])

    useEffect(() => {
        setValidUnique(unique_regex.test(unique));
    }, [unique])

  //password regex
  useEffect(() => {
    setValidPassword(password_regex.test(password))
    setValidMatch(password === matchPassword)
  }, [password, matchPassword])

  useEffect(() => {
    setErrMsg('')
  }, [password, matchPassword]) //email

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validUsername || !validUnique || !validPassword || !validMatch) {
      setErrMsg('Invalid input.')
      setPassword('')
      setMatchPassword('')
      return
    }

    Swal.fire({
      icon : "info",
      showConfirmButton: false,
      text : "Registering..."
    });

    axios.post(`/api/registration`, {
      username,
      plantilla_unique: unique,
      //email,
      password,
      password_confirmation: matchPassword
    })
    .then((response) => {
      if (response.data !== '') {
        Swal.update({
            icon:"success",
            title:"Registration Succesful",
            text:"You may now login.",
            showConfirmButton: true
        });
        //console.log(response.data);
        //localStorage.setItem('user', JSON.stringify(response.data))
        navigate('/login')
      }
    })
    .catch((error) => {
      //console.log(error);
      Swal.close();
      if (!error?.response) {
        setErrMsg("No Server Response.")
      } else {
        const errorStr = Object.values(error.response.data.errors)
        .map(arr => arr[0])
        .join(" ");
        setErrMsg(errorStr)
      }
    })
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow mt-3">
            <div className="card-header bg-light p-1">
              <h4 className="text-center">Registration</h4>
            </div>

            <div className="card-body">
              <p ref={errRef} className={errMsg ? "error-message" : "offscreen"}>{errMsg}</p>
              <form onSubmit={handleSubmit}>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-8">
                    <label htmlFor="username">
                      Username
                      <CheckIcon className={validUsername ? "valid" : "hide"} />
                      <ClearIcon className={validUsername || !username ? "hide" : "invalid"} />
                    </label>
                    <input type="text" className="form-control" id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onFocus={() => setUsernameFocus(true)}
                      onBlur={() => setUsernameFocus(false)}
                      ref={focusRef}
                    // required
                    />
                    <p className={usernameFocus && !validUsername ? "instructions" : "offscreen"}>
                      <InfoIcon />
                      Minimum 8 alphanumeric characters.<br />
                    </p>

                    <label htmlFor="unique" className="mt-2">Unique ID
                      <CheckIcon className={validUnique ? "valid" : "hide"} />
                      <ClearIcon className={validUnique || !unique ? "hide" : "invalid"} />
                    </label>
                    <input type="text" className="form-control" id="unique"
                      value={unique}
                      onChange={(e) => setUnique(e.target.value)}
                      onFocus={() => setUniqueFocus(true)}
                      onBlur={() => setUniqueFocus(false)}
                    // required
                    />

                    <p className={uniqueFocus && !validUnique ? "instructions" : "offscreen"}>
                      <InfoIcon />
                      Enter unique ID provided to you.<br />
                    </p>

                    <label htmlFor="password" className="mt-2">
                      Password
                      <CheckIcon className={validPassword ? "valid" : "hide"} />
                      <ClearIcon className={validPassword || !password ? "hide" : "invalid"} />
                    </label>
                    <button
                        type="button"
                        className="ms-1 btn btn-sm border-0 btn-light"
                        onClick={togglePasswordVisibility}
                        >
                        {showPassword ? (
                            <VisibilityIcon fontSize="small" /> // Eye-slash icon
                        ) : (
                            <VisibilityOffIcon fontSize="small" /> // Eye icon
                        )}
                    </button>
                    <input type={showPassword ? "text" : "password"} className="form-control" id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setPasswordFocus(true)}
                      onBlur={() => setPasswordFocus(false)}
                    // required
                    />
                    <p className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                      <InfoIcon />
                      Minimum 8 characters.<br />
                      Must include uppercase and lowercase letters and a number.<br />
                    </p>

                    <label htmlFor="confirm_password" className="mt-2">
                      Confirm Password
                      <CheckIcon className={validMatch && matchPassword ? "valid" : "hide"} />
                      <ClearIcon className={validMatch || !matchPassword ? "hide" : "invalid"} />
                    </label>
                    <button
                        type="button"
                        className="ms-1 btn btn-sm border-0 btn-light"
                        onClick={togglePasswordVisibility}
                        >
                        {showPassword ? (
                            <VisibilityIcon fontSize="small" /> // Eye-slash icon
                        ) : (
                            <VisibilityOffIcon fontSize="small" /> // Eye icon
                        )}
                    </button>
                    <input type={showPassword ? "text" : "password"} className="form-control" id="confirm_password"
                      value={matchPassword}
                      onChange={(e) => setMatchPassword(e.target.value)}
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                    // required
                    />
                    <p className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                      <InfoIcon />
                      Must match the first password input field.
                    </p>
                  </div>
                </div>

                <div className="form-group text-center">
                  <button className="btn btn-primary col-md-3" type="submit" disabled={!validUsername || !validUnique || !validPassword || !validMatch ? true : false}>Sign up</button>
                </div>
                <hr />
                <div className="form-group text-center">
                  Alread have an account? <a href="/login">Login here</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration
